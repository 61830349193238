body {
  background: #282a36;
  color: #f8f8f2;
}

a {
  color: #50fa7b;
}

a:hover {
  color: #90faaa;
}

body > .container {
  margin-top: 20vh;
}

.name {
  color: #ce6358;
}

.vector-selfie {
  max-width: 100%;
}

.speech-bubble {
  margin: 0 0 10px 0;
  cursor: default;
  box-sizing: border-box;
  --bbArrowSize: 1.5rem;
  --bbBorderRadius: 0.2rem;
  --bbPadding: 1rem 3rem;
  background: var(--bbColor);
  border-radius: var(--bbBorderRadius);
  padding: var(--bbPadding);
  position: relative;
  filter: drop-shadow(0px 0px 0.2rem #282a36);
  transition: transform 0.25s ease;
  margin-left: var(--bbArrowSize);
  grid-area: lc;
  --bbColor: #44475a;
  letter-spacing: .01rem;
}

.speech-bubble::before {
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 50%, 100% 12.5%, 100% 87.5%);
  right: calc(100% - 2px);
  --height: calc(var(--bbArrowSize) / 2 * 4);
  width: var(--bbArrowSize);
  height: var(--height);
  content: '';
  position: absolute;
  background: var(--bbColor);
}

.speech-bubble:nth-of-type(1)::before {
  clip-path: polygon(0 80%, 100% 12.5%, 100% 84.5%);
}

.speech-bubble:nth-of-type(4)::before {
  clip-path: polygon(0 30%, 100% 12.5%, 100% 87.5%);
}

.icon {
  font-size: 36px;
  margin: 0 12px;
  text-decoration: none;
}

.contacts {
  margin: 8px -2px 0 0;
  text-align: center;
}

@media (max-width: 768px) {
  .speech-bubble::before {
    display: none;
  }
}